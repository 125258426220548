<template>
  <p-base>
    <template v-slot:message>
      Придумайте новый пароль
    </template>
    <template v-slot:form>
      <p-input v-model:value="state.password" placeholder="Новый пароль" type="password"
                 :error="state.error" class="w-100"/>
      <p-input v-model:value="state.passwordRepeat" placeholder="Повторите новый пароль" type="password"
                 class="w-100"/>
    </template>
    <template v-slot:actions>
      <button
        class="btn btn-dark w-100 mb-3"
        @click.prevent="changePassword"
        :disabled="state.password !== state.passwordRepeat"
      >
        Далее
      </button>
      <p-server-error :error="state.serverError"/>
    </template>
  </p-base>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PServerError from '@/components/ui/ServerError'
import { defineEmits, reactive } from 'vue'
import { useStore } from 'vuex'
import PBase from './Base'

const passwordRe = RegExp(process.env.VUE_APP_PASSWORD_RE)

const store = useStore()
const state = reactive({
  password: null,
  passwordRepeat: null,
  serverError: null,
})
const emit = defineEmits(['success'])

const changePassword = async () => {
  state.serverError = null
  if (!passwordRe.test(state.password)) {
    state.serverError = 'Пароль должен быть не короче 8 символов, а также содержать хотябы одну букву и цифру'
    return
  }

  try {
    await api.get(urls.AUTH.RESET_PASSWORD, {
      params: {
        username: store.state.username,
        code: store.state.resetPasswordCode,
        password: state.password,
      },
    })
    store.commit('passwordReset')
    emit('success')
  } catch (err) {
    state.serverError = err.message
  }
}
</script>

<style scoped>

</style>
