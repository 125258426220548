<template>
  <div class="container d-flex justify-content-center py-5">
    <p-reset-password-request-form class="col-md-3" v-if="state === null && !success"/>
    <p-reset-password-code-form class="col-md-3" v-if="state === 'code'"/>
    <p-reset-password-change-password-form class="col-md-3" v-if="state === 'change_password'"
                                             @success="success = true"/>
    <p-reset-password-success class="col-md-3" v-if="state === null && success"/>
  </div>
</template>

<script setup>
import PResetPasswordRequestForm from '@/components/forms/reset-password/RequestCode'
import PResetPasswordCodeForm from '@/components/forms/reset-password/Code'
import PResetPasswordChangePasswordForm from '@/components/forms/reset-password/ChangePassword'
import PResetPasswordSuccess from '@/components/forms/reset-password/Success'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = computed(() => store.state.resetPasswordState)
const success = ref(false)
</script>

<style scoped>

</style>
