<template>
  <p-base>
    <template v-slot:message>
      Введите Email или номер телефона, который вы использовали для входа
    </template>
    <template v-slot:form>
      <p-username-input
        :username="username"
        @update:username="store.commit('setUsername', $event)"
        :error="v$.username.$error"
      />
    </template>
    <template v-slot:actions>
      <button class="btn btn-dark w-100 mb-3" @click.prevent="requestReset">Далее</button>
      <p-server-error :error="serverError"/>
    </template>
  </p-base>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PBase from './Base'

import PUsernameInput from '@/components/ui/UsernameInput'
import PServerError from '@/components/ui/ServerError'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const username = computed(() => store.state.username)
const serverError = ref(null)

const rules = {
  username: { required },
}
const v$ = useVuelidate(rules, { username })

const requestReset = async () => {
  serverError.value = null
  const ok = await v$.value.$validate()
  if (!ok) {
    return
  }

  try {
    await api.get(urls.AUTH.REQUEST_PASSWORD_RESET, {
      params: { username: username.value },
    })
    store.commit('resetPasswordRequested')
  } catch (err) {
    serverError.value = err.message
  }
}
</script>

<style scoped>

</style>
