<template>
  <div>
    <form class="align-items-center d-flex flex-column text-center">
      <h5 class="mb-3">Восстановить пароль</h5>
      <div class="fw-lighter mb-3">
        <slot name="message"></slot>
      </div>
      <slot name="form"></slot>
      <slot name="actions"></slot>
    </form>
  </div>
</template>

<script>
</script>

<style scoped>
</style>
