<template>
  <p-base>
    <template v-slot:message>
      Мы отправили вам SMS с кодом восстановления
    </template>
    <template v-slot:form>
      <div class="w-100 mb-3">
        <div class="w-100 d-flex justify-content-between flex-row" v-if="timer > 0">
          <div class="fw-lighter">Можно запросить повторно через:</div>
          <div>{{ timerVerbose }}</div>
        </div>

        <div class="w-100 d-flex justify-content-between flex-row" v-else>
          <div class="fw-lighter">Не получили код?</div>
          <div class="text-decoration-underline fs-6 pointer" @click="resendCode">Отправить ещё раз</div>
        </div>
      </div>

      <input
        class="form-control mb-3"
        :class="{'is-invalid': serverError}"
        type="text"
        placeholder="Код восстановления"
        v-maska="'#####'"
        v-model="code"
        aria-describedby="validationFeedback"
      >
    </template>
    <template v-slot:actions>
      <button class="btn btn-dark w-100 mb-3" @click.prevent="sendCode" :disabled="code === null">Далее</button>
      <p-server-error :error="serverError"/>
    </template>
  </p-base>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PServerError from '@/components/ui/ServerError'
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { useStore } from 'vuex'
import PBase from './Base'

const RESEND_DELAY = 3 * 60

const store = useStore()
const code = ref(null)
const serverError = ref(null)
const timer = ref(RESEND_DELAY)

const timerVerbose = computed(() =>
  `${Math.floor(timer.value / 60)}:${String(timer.value % 60).padStart(2, '0')}`
)

const resendCode = async () => {
  timer.value = RESEND_DELAY
}

const sendCode = async () => {
  serverError.value = null

  try {
    await api.get(urls.AUTH.VERIFY_PASSWORD_RESET_CODE, {
      params: {
        username: store.state.username,
        code: code.value,
      },
    })
    store.commit('codeValid', code.value)
  } catch (err) {
    serverError.value = err.message
  }
}

let interval = null
onMounted(() => {
  interval = setInterval(() => {
    timer.value -= 1
  }, 1000)
})
onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
